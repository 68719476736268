import React, { Component } from "react";
import "./Login.css";

class Login extends Component {
  render() {
    return (
      <div className="main">
        <div className="container-login">
          <div
            className="wrap-login"
            style={{
              backgroundImage: "url(/img/bg-01.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div>
              <form className="login-form">
                <span className="login-form-title">Login To Continue</span>
                <div className="wrap-input">
                  <input className="input100" type="text" name="email"></input>
                  <span class="focus-input100"></span>
                  <span class="label-input100">Email</span>
                </div>
                <div className="wrap-input">
                  <input
                    className="input100"
                    type="password"
                    name="password"
                  ></input>
                  <span class="focus-input100"></span>
                  <span class="label-input100">Password</span>
                </div>
                <div class="flex-sb-m w-full p-t-3 p-b-32">
                  <div class="contact100-form-checkbox">
                    <input
                      class="input-checkbox100"
                      id="ckb1"
                      type="checkbox"
                      name="remember-me"
                    />
                    <label class="label-checkbox100" for="ckb1">
                      Remember me
                    </label>
                  </div>

                  <div>
                    <a href="#" class="txt1">
                      Forgot Password?
                    </a>
                  </div>
                </div>
                <div class="container-login100-form-btn">
                  <button class="login100-form-btn">Login</button>
                </div>
                <div class="text-center p-t-46 p-b-20">
                  <span class="txt2"> or sign up using </span>
                </div>
                <div class="login100-form-social flex-c-m">
                  <a
                    href="#"
                    class="login100-form-social-item flex-c-m bg1 m-r-5"
                  >
                    <i
                      class="fa fa-brands fa-facebook-f"
                      aria-hidden="true"
                    ></i>
                  </a>

                  <a
                    href="#"
                    class="login100-form-social-item flex-c-m bg2 m-r-5"
                  >
                    <i class="fa fa-brands fa-twitter" aria-hidden="true"></i>
                  </a>
                </div>
              </form>
              <div class="login100-more" style={{}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
