import React, { Component } from "react";

class ManageITService extends Component {
  render() {
    return (
      <section className="page-section" id="mangedIT">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">
              Manged IT Services
            </h2>
            <h3 className="section-subheading text-muted">
              Our company provide variety services.
            </h3>
          </div>
          <div className="row text-center">
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fa-solid fa-network-wired fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">Network Management</h4>
              <p className="text-muted">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item" style={{ border: "none" }}>
                    Network design, implementation, and support
                  </li>
                  <li className="list-group-item" style={{ border: "none" }}>
                    Cabling and Installation
                    <br />
                    (Rack, Wifi AP, Router, Switches, Routers)
                  </li>
                  <li className="list-group-item " style={{ border: "none" }}>
                    Routine network assessments
                  </li>
                  <li className="list-group-item " style={{ border: "none" }}>
                    Firewalls, VPNs, and intrusion prevention systems
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fa-solid fa-cloud fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">Cloud Solution & Support</h4>
              <p className="text-muted">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item" style={{ border: "none" }}>
                    Microsoft Azure
                  </li>
                  <li className="list-group-item" style={{ border: "none" }}>
                    Amazon AWS
                  </li>
                  <li className="list-group-item " style={{ border: "none" }}>
                    Microsoft Office 365
                  </li>
                  <li className="list-group-item " style={{ border: "none" }}>
                    Google G Suite
                  </li>
                </ul>
              </p>
            </div>
            <div className="col-md-4">
              <span className="fa-stack fa-4x">
                <i className="fas fa-circle fa-stack-2x text-primary"></i>
                <i className="fa-solid fa-headset fa-stack-1x fa-inverse"></i>
              </span>
              <h4 className="my-3">Help Desk Services</h4>
              <p className="text-muted">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item" style={{ border: "none" }}>
                    24/7 Help Desk
                  </li>
                  <li className="list-group-item" style={{ border: "none" }}>
                    Remote Support
                  </li>
                  <li className="list-group-item" style={{ border: "none" }}>
                    Performance Review & Reporting
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ManageITService;
