import React, { Component } from "react";

class Header extends Component {
  componentDidMount() {}

  render() {
    return (
      <header className="masthead">
        <div className="container">
          <div className="masthead-subheading">Welcome To Gluatech, Inc</div>
          <div className="masthead-heading text-uppercase">
            Implement Your Idea
          </div>
          <a className="btn btn-primary btn-xl text-uppercase" href="#mangedIT">
            Tell Me More
          </a>
        </div>
      </header>
    );
  }
}

export default Header;
