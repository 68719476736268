import React, { Component } from "react";
import Image1 from "../../assets/img/portfolio/1.jpg";
import Image2 from "../../assets/img/portfolio/2.jpg";
import Image3 from "../../assets/img/portfolio/3.jpg";
import Image4 from "../../assets/img/portfolio/4.jpg";
import Image5 from "../../assets/img/portfolio/5.jpg";
import Image6 from "../../assets/img/portfolio/6.jpg";

class ApplicationDevelopment extends Component {
  render() {
    return (
      <section className="page-section bg-light" id="ApplicationDevelopment">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">
              Application Developments
            </h2>
            <h3 className="section-subheading text-muted">
              <b>What We DO...</b> <br />
              We deliver a range of custom software products and solutions for
              desktop, web and mobile.
              <br />
              From idea validation to custom app development, testing,
              governance and support.
            </h3>
            <h3></h3>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  href="#portfolioModal1"
                >
                  {/* <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div> */}
                  <img className="img-fluid" src={Image1} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    Desktop Software
                  </div>
                  <div className="portfolio-caption-subheading text-muted">
                    Universal Windows Application Expert
                  </div>
                  <div className="portfolio-caption-subheading text-muted">
                    Desktop software modernization (Legacy to Modern)
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  href="#portfolioModal2"
                >
                  {/* <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div> */}
                  <img className="img-fluid" src={Image2} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    Web Application
                  </div>
                  <div className="portfolio-caption-subheading text-muted">
                    Launch beautiful, secure and scalable apps, SaaS products,
                    and platforms
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  href="#portfolioModal3"
                >
                  <div className="portfolio-hover">
                    {/* <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div> */}
                  </div>
                  <img className="img-fluid" src={Image3} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">Mobile App</div>
                  <div className="portfolio-caption-subheading text-muted">
                    Build tailor-made native apps that are secure, flawless and
                    meticulously designed for iPhone, iPad and any Android
                    devices
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-lg-0">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  href="#portfolioModal4"
                >
                  {/* <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div> */}
                  <img className="img-fluid" src={Image4} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    Data Integration (EDI, Web API)
                  </div>
                  <div className="portfolio-caption-subheading text-muted">
                    Connect with any data source, Extract and consolidate
                    exactly the data you need, Ensure the data is
                    fit-for-purpose,Deliver the data how and when it’s needed
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-4 mb-sm-0">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  href="#portfolioModal5"
                >
                  <div className="portfolio-hover">
                    {/* <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div> */}
                  </div>
                  <img className="img-fluid" src={Image5} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    Database Warehouse and BI solutions
                  </div>
                  <div className="portfolio-caption-subheading text-muted">
                    Gain Complete Visibility and Control of Your Data With Data
                    Warehouse Automation
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="portfolio-item">
                <a
                  className="portfolio-link"
                  data-bs-toggle="modal"
                  href="#portfolioModal6"
                >
                  {/* <div className="portfolio-hover">
                    <div className="portfolio-hover-content">
                      <i className="fas fa-plus fa-3x"></i>
                    </div>
                  </div> */}
                  <img className="img-fluid" src={Image6} alt="..." />
                </a>
                <div className="portfolio-caption">
                  <div className="portfolio-caption-heading">
                    RPA (Robotic Process Automation)
                  </div>
                  <div className="portfolio-caption-subheading text-muted">
                    Simplify workflows with the fastest and most cost-effective
                    robotic process automation (RPA).
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ApplicationDevelopment;
