import React, { Component } from "react";
import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./App.css";
import "./styles.css";

import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import MangedITService from "./components/Service/MangedIT";
import SoftwareDevelopment from "./components/Service/ApplicationDevelopment";
import About from "./components/About/About";
import Team from "./components/Team/Team";
import Contact from "./components/Contact/Contact";
import ChatButton from "./components/ChatTool/ChatButton";

class Home extends Component {
  render() {
    return (
      <div className="App" id="page-top">
        <Navbar></Navbar>
        <Header></Header>
        <MangedITService></MangedITService>
        <SoftwareDevelopment></SoftwareDevelopment>
        <About></About>
        <Team></Team>
        <Contact></Contact>
        <Helmet>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script>
          <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script>
        </Helmet>
        <ChatButton></ChatButton>
      </div>
    );
  }
}

export default Home;
