import React, { Component } from "react";
import logo from "../../assets/img/navbar-logo.png";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { isCollapse: true };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  nav = React.createRef();

  handleScroll = () => {
    const lastScrollY = window.scrollY;
    if (lastScrollY !== undefined) {
      if (lastScrollY > 0) {
        this.nav.current.classList.add("navbar-shrink");
      } else {
        this.nav.current.classList.remove("navbar-shrink");
      }
    }
  };

  render() {
    return (
      <nav
        ref={this.nav}
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="mainNav"
      >
        <div className="container">
          <a className="navbar-brand" href="#page-top">
            <img src={logo} alt="..." />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => this.setState({ isCollapse: false })}
          >
            Menu
            <i className="fas fa-bars ms-1"></i>
          </button>
          <div
            className={`collapse navbar-collapse ${
              this.state.isCollapse ? "" : "show"
            }`}
            id="navbarResponsive"
          >
            <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#mangedIT"
                  onClick={() => this.setState({ isCollapse: true })}
                >
                  Managed IT Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#ApplicationDevelopment"
                  onClick={() => this.setState({ isCollapse: true })}
                >
                  Application Developments
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#about"
                  onClick={() => this.setState({ isCollapse: true })}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#team"
                  onClick={() => this.setState({ isCollapse: true })}
                >
                  Team
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#contact"
                  onClick={() => this.setState({ isCollapse: true })}
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="login"
                  onClick={() => this.setState({ isCollapse: true })}
                >
                  Sign In
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
