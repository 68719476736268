import React, { Component } from "react";
import Image1 from "../../assets/img/about/1.jpg";
import Image2 from "../../assets/img/about/2.jpg";
import Image3 from "../../assets/img/about/3.jpg";
import Image4 from "../../assets/img/about/4.jpg";

class About extends Component {
  render() {
    return (
      <section className="page-section" id="about">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">About</h2>
            <h3 className="section-subheading text-muted">
              Our Gluatech Journey Begins
            </h3>
          </div>
          <ul className="timeline">
            <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src={Image1}
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>July 2021</h4>
                  <h4 className="subheading">Establishment</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted"></p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src={Image2}
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>August 2021</h4>
                  <h4 className="subheading">Software Project Team</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted"></p>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src={Image3}
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>December 2021</h4>
                  <h4 className="subheading">Network Service Team</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted"></p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img
                  className="rounded-circle img-fluid"
                  src={Image4}
                  alt="..."
                />
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>July 2022</h4>
                  <h4 className="subheading">Phase Two Expansion</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted"></p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <h4>
                  Continuing
                  <br />
                  Our
                  <br />
                  Journey!
                </h4>
              </div>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default About;
