import React, { Component } from "react";
import "./ChatTool.css";

class ChatButton extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="bottomright">
          <span className="fa-stack fa-2x">
            <i className="fas fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-brands fa-rocketchat fa-stack-1x fa-inverse"></i>
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default ChatButton;
